import "./Projects.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function Project(props) {
  return (
    <Card
      sx={{
        maxWidth: { xs: 300, sm: 500, md: 900 },
        boxShadow: "20px 20px whitesmoke",
      }}
      className="projectCard"
    >
      <CardMedia
        sx={{ height: 500 }}
        image={require(`./${props.project.img}`)}
        component="img"
        onClick={() => window.open(props.project.url)}
      />
      <CardContent sx={{ backgroundColor: "var(--secondary)" }}>
        <Typography gutterBottom variant="h5" component="div">
          {props.project.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.project.descriptionShort}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href={props.project.url} target="_blank">
          Visit
        </Button>
        <Button size="small" href={props.project.github} target="_blank">
          Github
        </Button>
      </CardActions>
    </Card>
  );
}
