import "./About.css";
import { useState } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Tooltip from "@mui/material/Tooltip";
import { copyToClipboard } from "../../utils";
const fullBody = require("./fullBody.jpg");

function About(props) {
  const [tooltipTxt, setTooltipTxt] = useState("Click to copy");
  const handleEmailClick = () => {
    copyToClipboard("ellensaylor8@gmail.com");
    setTooltipTxt("Copied");
  };
  const services = [
    "Planning, scoping and requirements gathering",
    "Development",
    "Testing",
    "Quality Assurance",
    "Deployment",
    "Upgrades & Maintenance",
  ];

  const servicesComponent = (
    <>
      {services.map((service) => {
        return (
          <Paper
            elevation={3}
            className="servicesPaper"
            sx={{
              textAlign: "center",
              color: "var(--secondary)",
              backgroundColor: "var(--primary)",
              height: "60",
              lineHeight: "60px",
              fontSize: "20px",
              borderRadius: "20px",
              margin: "1rem",
              padding: "0.5rem",
            }}
            key={service}
          >
            {service}{" "}
          </Paper>
        );
      })}
    </>
  );
  return (
    <>
      <Grid container id="aboutGreeting" justifyContent="center">
        <Grid item xs={12}>
          {" "}
          <Typography
            variant="h1"
            sx={{ marginTop: "5rem", marginBottom: "5rem" }}
            align="center"
          >
            I'm Ellen
          </Typography>
        </Grid>
        <Grid item md={5}>
          <div id="aboutPicDiv">
            <img id="aboutPic" src={fullBody} alt="aboutPic"></img>
          </div>
        </Grid>
        <Grid item xs={12} md={6} id="aboutTxt">
          <Typography variant="h3" sx={{ marginBottom: "2rem" }}>
            {" "}
            I am a Full-Stack Software Developer with three years of experience
            building custom software solutions.
          </Typography>
          <Typography variant="h6">
            {" "}
            I spent the last several years using software to help businesses
            operate more efficiently and gain a competitive advantage.
            <br />
            <br />
            I have worked with technologies such as React, Vue, Node, Express,
            Google Cloud Platform, Firebase, SQL and Tableau.
            <br />
            <br />
            In 2022 I made the decision to take a step back from full-time work
            and spend more time with my young children. I am interested in
            moving into a part-time role to provide the flexibility needed for a
            working mom. I am open to contract or part-time projects!
          </Typography>
        </Grid>
      </Grid>
      <Grid container id="services" spacing={2} sx={{ padding: "4rem" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ padding: "2rem" }} align="center">
            {" "}
            <br />I can take your website idea from just an idea to the final
            end product and can assist with any stage of the software
            development life cycle.
          </Typography>{" "}
          {props.mobile && servicesComponent}
          <Typography
            variant="h3"
            sx={{ margin: "3rem 0 3rem 0" }}
            align="center"
          >
            {" "}
            Let's get to work!
          </Typography>
          <div id="contactIconsDiv">
            <Tooltip title={tooltipTxt} placement="top-start">
              <Button className="contactIcon">
                <EmailIcon
                  fontSize="large"
                  color="var(--primary)"
                  onClick={handleEmailClick}
                  onMouseEnter={() => setTooltipTxt("Click to copy")}
                />
              </Button>
            </Tooltip>
            <Button
              className="contactIcon"
              onClick={() =>
                window.open("https://www.linkedin.com/in/ellensaylor/")
              }
            >
              <LinkedInIcon fontSize="large" color="var(--primary)" />
            </Button>
          </div>
        </Grid>
        {!props.mobile && (
          <Grid item md={6}>
            {servicesComponent}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default About;
