import "./RightLanding.css";
import ProfilePic from "../../ProfilePic/ProfilePic";

function RightLanding() {
  return (
    <div className="rightLanding">
      <ProfilePic></ProfilePic>
    </div>
  );
}

export default RightLanding;
