import "./ProfilePic.css";
const halfShot = require("./headshot.jpg");

function ProfilePic() {
  return (
    <div id="profilePicDiv">
      <img
        id={!document.referrer ? "animateProfilePic" : "profilePic"}
        src={halfShot}
        alt="halfshot"
      ></img>
    </div>
  );
}

export default ProfilePic;
