import "./Portfolio.css";
import Project from "./Projects/Projects.js";
import Grid from "@mui/material/Grid";

function Portfolio(props) {
  const projects = [
    {
      title: "Coffee",
      descriptionShort:
        "Global coffee imports and a local coffee shop search built using Google Maps JavaScript API.",
      url: "https://coffee-frontend-wtqy5bq3pa-uc.a.run.app/",
      img: props.mobile ? "CoffeeAppPic_Mobile.png" : "CoffeeAppPic.png",
      github: "https://github.com/ellensaylor8/coffee",
    },
    {
      title: "Haiti Home of Hope",
      descriptionShort: "A website revamp for a Haiti based ministry.",
      url: "https://haiti-home-of-hope.web.app/",
      img: props.mobile ? "HHH_Homepage_Mobile.png" : "HHH_Homepage.png",
      github: "https://github.com/ellensaylor8/Haiti-Home-Of-Hope",
    },
  ];
  return (
    <Grid
      container
      spacing={4}
      id="portfolioDiv"
      alignItems="center"
      direction="column"
    >
      {projects.map((project) => {
        return (
          <Grid item xs={12} md={8} key={project.title}>
            <Project project={project} mobile={props.mobile} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Portfolio;
