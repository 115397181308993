import LeftLanding from "./LeftLanding/LeftLanding";
import RightLanding from "./RightLanding/RightLanding";
import Grid from "@mui/material/Grid";

function Home(props) {
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <LeftLanding mobile={props.mobile} />
      </Grid>
      {!props.mobile && (
        <Grid item xs={12} sm={6}>
          <RightLanding />
        </Grid>
      )}
    </Grid>
  );
}

export default Home;
