import "./LeftLanding.css";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { copyToClipboard, openEmailClient } from "../../../utils";
const resume = require("./EllenSaylor_Resume.pdf");
// const headshot = require("./headshot.jpg");
// const greetingSVG = require('../../../../public/Greeting.svg')

function LeftLanding(props) {
  const email = "ellensaylor8@gmail.com";
  const handleResumeClick = () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = resume;
    link.click();
  };

  const handleEmailClick = () => {
    copyToClipboard(email);
    openEmailClient(email);
  };

  return (
    <div className="container">
      <div id="greetingDiv">
        <p id="greeting">Hello, my name is</p>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="100px"
          width="100%"
          viewBox="20 120 698 120"
        >
          <path
            className="path lastName"
            stroke="#c0a58e"
            strokeWidth="1"
            fill="#c0a58e"
            d="
M264.119110,144.899704 
	C278.225830,141.135681 290.601257,144.983734 296.097504,154.898361 
	C297.393341,157.235886 297.802368,160.065033 298.716827,162.978531 
	C293.702393,164.260422 290.634338,164.855225 288.311371,159.255997 
	C284.373016,149.763077 266.013733,148.858353 260.019379,157.356140 
	C256.708557,162.049683 257.922852,168.262436 263.263367,170.978638 
	C266.907013,172.831833 271.046783,173.734207 274.999451,174.948746 
	C279.593323,176.360321 284.436951,177.165649 288.800690,179.080673 
	C301.927124,184.841293 303.966095,201.455338 292.892090,210.593994 
	C282.645416,219.049896 261.216034,217.842270 252.468216,207.894363 
	C249.919174,204.995621 248.703064,200.924713 246.612152,196.880875 
	C252.565811,195.407684 255.935883,195.212463 258.165375,200.806793 
	C260.408997,206.436600 269.834808,209.202530 278.118958,207.781342 
	C279.908661,207.474304 281.680939,206.947601 283.393921,206.334030 
	C288.163025,204.625809 290.700500,200.992264 290.820251,196.111069 
	C290.939941,191.232086 287.948486,187.982162 283.548615,186.398102 
	C279.657593,184.997269 275.564728,184.154648 271.555786,183.085068 
	C269.311249,182.486252 267.028931,182.015900 264.810516,181.334885 
	C254.780319,178.255692 249.240433,171.984756 249.104401,163.651596 
	C248.967697,155.277939 254.081482,148.756027 264.119110,144.899704 
z"
          />
          <path
            className="path firstName"
            stroke={props.mobile ? "#c0a58e" : "#000000"}
            strokeWidth="1"
            fill="#000000"
            d="
M34.965313,153.996948 
	C34.943752,161.144196 34.943752,167.787415 34.943752,174.846283 
	C45.877159,174.846283 56.619987,174.846283 67.665039,174.846283 
	C67.665039,177.637238 67.665039,180.024414 67.665039,182.840424 
	C56.912544,182.840424 46.167896,182.840424 35.185337,182.840424 
	C35.185337,191.000900 35.185337,198.744354 35.185337,206.984680 
	C46.581387,206.984680 57.975281,206.984680 69.597305,206.984680 
	C69.597305,209.743622 69.597305,212.013657 69.597305,214.650452 
	C55.322197,214.650452 40.938160,214.650452 26.293865,214.650452 
	C26.293865,191.471405 26.293865,168.245270 26.293865,144.788452 
	C40.669395,144.788452 54.941795,144.788452 69.525497,144.788452 
	C69.525497,147.136368 69.525497,149.378632 69.525497,152.427917 
	C61.415493,152.427917 53.179180,152.328369 44.947327,152.476593 
	C41.622154,152.536484 38.306732,153.137924 34.965313,153.996948 
z"
          />
          <path
            className="path firstName"
            stroke={props.mobile ? "#c0a58e" : "#000000"}
            strokeWidth="1"
            fill="#000000"
            d="
M142.171722,215.947495 
	C127.569717,213.784286 119.798515,203.997406 120.781380,188.800095 
	C121.074837,184.262527 121.991447,179.466766 123.905800,175.392197 
	C128.446503,165.727631 136.646149,162.127716 147.913467,163.853806 
	C156.512299,165.171127 163.357712,172.227600 164.960678,181.700806 
	C165.504318,184.913483 165.569138,188.207153 165.889877,191.889923 
	C153.428696,191.889923 141.501999,191.889923 129.588043,191.889923 
	C129.450439,199.911057 132.544159,205.117325 138.533142,207.351257 
	C145.280975,209.868240 150.841614,207.794708 156.187958,202.159317 
	C157.872681,200.383499 161.600861,200.546341 164.845169,199.704803 
	C162.590515,210.550537 154.933151,215.823074 142.171722,215.947495 
M137.891953,171.975845 
	C132.366394,174.495499 130.049957,178.958069 129.633163,184.897614 
	C138.981064,184.897614 147.863007,184.897614 156.772675,184.897614 
	C155.432022,173.696152 149.195694,169.240387 137.891953,171.975845 
z"
          />
          <path
            className="path lastName"
            stroke="#c0a58e"
            strokeWidth="1"
            fill="#c0a58e"
            d="
M312.015015,171.147064 
	C319.175903,162.956223 331.238342,160.767075 340.391602,165.720825 
	C345.267212,168.359528 348.591370,172.441559 348.747986,177.940918 
	C349.092804,190.049515 348.855011,202.174728 348.855011,214.650269 
	C346.015259,214.650269 343.461792,214.650269 340.559448,214.650269 
	C340.346008,212.691620 340.136108,210.765305 340.060394,210.070526 
	C335.115723,211.980804 330.060455,215.152023 324.724182,215.730377 
	C314.968384,216.787720 308.041046,210.315277 307.385986,201.214828 
	C306.836792,193.585083 313.513641,187.046661 322.855347,186.074844 
	C325.663605,185.782700 328.469604,185.450562 331.284546,185.245560 
	C334.065491,185.043045 336.856445,184.978104 339.540314,184.857422 
	C340.795776,177.598831 339.080109,173.591156 334.149445,171.640991 
	C328.477936,169.397812 321.909302,171.374924 318.736053,176.280304 
	C316.687958,179.446381 316.352386,179.527771 309.140137,178.373123 
	C310.070496,175.999039 310.956940,173.737000 312.015015,171.147064 
M318.082642,205.449829 
	C323.069061,209.087509 329.897339,209.292847 334.711334,205.949921 
	C339.630493,202.533951 341.090576,198.156998 339.246124,192.115387 
	C335.826050,192.115387 332.362396,192.016785 328.908295,192.155884 
	C327.107574,192.228394 325.299316,192.617889 323.536591,193.040024 
	C317.119965,194.576706 315.167389,198.504593 318.082642,205.449829 
z"
          />
          <path
            className="path firstName"
            stroke={props.mobile ? "#c0a58e" : "#000000"}
            strokeWidth="1"
            fill="#000000"
            d="
M207.337616,178.297058 
	C204.174271,171.937424 201.036499,170.243744 194.423462,171.020798 
	C189.087448,171.647781 184.634125,175.221085 184.017288,180.853516 
	C183.223953,188.097443 183.383820,195.449310 183.214325,202.756561 
	C183.122055,206.734634 183.197876,210.716599 183.197876,214.817749 
	C180.294434,214.817749 177.682190,214.817749 174.950714,214.817749 
	C174.950714,197.996597 174.950714,181.371201 174.950714,164.432983 
	C177.615494,164.432983 180.183258,164.432983 182.915298,164.432983 
	C183.113693,166.270599 183.321121,168.191925 183.402817,168.948669 
	C188.006424,167.096909 192.472534,164.426559 197.265732,163.555023 
	C207.126724,161.762039 215.452240,167.904877 216.104141,178.023972 
	C216.881439,190.089539 216.287308,202.243439 216.287308,214.687180 
	C213.918167,214.687180 211.331879,214.687180 207.487503,214.687180 
	C207.487503,202.817978 207.487503,190.750763 207.337616,178.297058 
z"
          />
          <path
            className="path lastName"
            stroke="#c0a58e"
            strokeWidth="1"
            fill="#c0a58e"
            d="
M463.538849,166.249969 
	C477.105682,173.570602 478.620575,197.240234 469.284027,208.137451 
	C462.988617,215.485199 454.870483,217.438095 445.802032,215.235321 
	C436.216797,212.906998 431.029785,206.135635 429.053558,196.780350 
	C427.464722,189.259033 427.919556,181.780960 431.687714,174.990158 
	C437.959625,163.687286 450.390839,160.321030 463.538849,166.249969 
M462.171173,203.609619 
	C467.442963,196.951843 466.992279,189.313339 465.327881,181.785919 
	C463.810455,174.923080 458.666473,171.099014 452.242371,170.954224 
	C445.354492,170.798965 440.010834,174.232529 438.456207,181.395615 
	C437.369446,186.402985 437.133423,192.005646 438.193573,196.985565 
	C440.641205,208.482544 452.828766,211.939270 462.171173,203.609619 
z"
          />
          <path
            className="path lastName"
            stroke="#c0a58e"
            strokeWidth="1"
            fill="#c0a58e"
            d="
M357.163910,167.836258 
	C356.803650,166.528000 356.558716,165.589874 356.308136,164.629990 
	C364.836975,163.211624 364.718323,163.250031 367.241486,171.044937 
	C370.896698,182.337036 374.743927,193.566986 379.631226,204.824844 
	C381.854340,198.144623 384.074219,191.463333 386.301636,184.784546 
	C387.878174,180.057388 389.475128,175.337021 391.044586,170.607513 
	C393.361176,163.626556 393.354218,163.624252 401.825714,164.457413 
	C401.352844,166.039490 400.983276,167.555603 400.451447,169.012497 
	C394.584808,185.084030 388.769653,201.175018 382.783142,217.201935 
	C378.199463,229.473206 373.089050,232.882065 361.301666,231.624268 
	C361.301666,229.666855 361.301666,227.649796 361.301666,225.471359 
	C363.529175,225.048248 365.847626,224.854126 367.997955,224.148041 
	C372.440460,222.689301 375.376160,216.741898 373.824921,212.560715 
	C368.336151,197.766129 362.798431,182.989700 357.163910,167.836258 
z"
          />
          <path
            className="path firstName"
            stroke={props.mobile ? "#c0a58e" : "#000000"}
            strokeWidth="1"
            fill="#000000"
            d="
M110.829773,189.000076 
	C110.829773,197.800995 110.829773,206.101929 110.829773,214.713135 
	C107.961151,214.713135 105.369698,214.713135 102.434280,214.713135 
	C102.434280,191.644287 102.434280,168.412643 102.434280,144.851318 
	C105.029778,144.851318 107.755875,144.851318 110.829781,144.851318 
	C110.829781,159.580490 110.829781,174.040283 110.829773,189.000076 
z"
          />
          <path
            className="path firstName"
            stroke={props.mobile ? "#c0a58e" : "#000000"}
            strokeWidth="1"
            fill="#000000"
            d="
M87.625931,144.535400 
	C88.649017,145.511963 89.750290,146.476532 89.753929,147.445206 
	C89.836937,169.542923 89.801590,191.641098 89.768929,213.739151 
	C89.768501,214.028015 89.517456,214.316513 89.286743,214.813263 
	C86.787857,214.813263 84.202248,214.813263 81.354736,214.813263 
	C81.354736,191.516968 81.354736,168.328613 81.354736,144.525314 
	C83.303848,144.525314 85.248337,144.525314 87.625931,144.535400 
z"
          />
          <path
            className="path lastName"
            stroke="#c0a58e"
            strokeWidth="1"
            fill="#c0a58e"
            d="
M484.238068,203.972198 
	C484.238098,192.676926 484.236053,181.877640 484.238312,171.078339 
	C484.239868,163.561218 484.242523,163.561218 492.702148,164.485550 
	C492.858521,166.590988 493.022034,168.792618 493.185547,170.994247 
	C492.875977,170.632812 492.566437,170.271378 492.256897,169.909943 
	C494.634369,168.218185 496.820587,166.071594 499.445343,164.967926 
	C501.868805,163.948898 504.762573,164.048386 507.449219,163.655273 
	C507.751251,166.183441 508.053284,168.711594 508.360382,171.282104 
	C496.545441,171.241989 493.024292,174.842346 493.007660,186.279831 
	C492.994141,195.570648 493.004913,204.861481 493.004913,214.576477 
	C490.191071,214.576477 487.641235,214.576477 484.237823,214.576477 
	C484.237823,211.282654 484.237823,207.875412 484.238068,203.972198 
z"
          />
          <path
            className="path lastName"
            stroke="#c0a58e"
            strokeWidth="1"
            fill="#c0a58e"
            d="
M409.994385,202.983795 
	C409.994385,183.531799 409.994385,164.576172 409.994385,145.208374 
	C412.743500,145.208374 415.107788,145.208374 417.841705,145.208374 
	C417.841705,168.359879 417.841705,191.383942 417.841705,214.702881 
	C415.345703,214.702881 413.075897,214.702881 409.994385,214.702881 
	C409.994385,210.986099 409.994385,207.233124 409.994385,202.983795 
z"
          />
        </svg>
        <p id="greetingBlurb">
          I am a Full-Stack Software Developer and am looking for new projects.
          Send me an{" "}
          <Tooltip title="Click to copy email" placement="top">
            <Button
              sx={{
                minWidth: "auto",
                padding: "0px",
              }}
              onClick={handleEmailClick}
            >
              email
            </Button>
          </Tooltip>{" "}
          or reach out on{" "}
          <Button
            sx={{
              minWidth: "auto",
              padding: "0px",
            }}
            onClick={() =>
              window.open("https://www.linkedin.com/in/ellensaylor/")
            }
          >
            LinkedIn
          </Button>{" "}
          if you would like to work together!
        </p>
        <Button onClick={handleResumeClick} variant="outlined" id="viewResume">
          View Resume
        </Button>
      </div>
    </div>
  );
}

export default LeftLanding;
