import "./App.css";
import Home from "./components/Home/Home.js";
import ResponsiveAppBar from "./components/ResponsiveAppBar/ResponsiveAppBar";
import About from "./components/About/About.js";
import Portfolio from "./components/Portfolio/Portfolio";
import { Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "#c0a58e",
          borderColor: "#c0a58e",
        },
      },
    },
  },
});

function App() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window.innerWidth <= 600);
    window.addEventListener("resize", () => {
      setMobile(window.innerWidth <= 600);
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ResponsiveAppBar mobile={mobile} />
        <Routes>
          <Route path="/" element={<Home mobile={mobile} />} />
          <Route path="/about" element={<About mobile={mobile} />} />
          <Route path="/portfolio" element={<Portfolio mobile={mobile} />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
